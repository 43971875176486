import { useCallback } from "react";

import { useAimoParkingClient } from "hooks/aimoParking/useAimoParkingClient";
import { useSwr } from "hooks/aimoParking/useSwr";
import { formatQueryParams } from "utils/stringUtils";

export const useFindAllEdgeDevice = ({ pageIndex, pageSize = 25 }: { pageIndex?: number; pageSize?: number }) => {
  const { data, error } = useSwr<AimoParkingEdgeDevicesResponse>(
    `/edge-device?${formatQueryParams({ pageSize, pageIndex })}`
  );

  return {
    data: data?.data,
    pageInfo: data?.pagination && {
      hasMore: data.pagination.hasMore,
      pageIndex: data.pagination.pageIndex,
      totalPages: data.pagination.totalPages,
      totalItems: data.pagination.totalItems,
    },
    error,
  };
};

export const useFindEdgeDevice = ({
  edgeDeviceId,
  revalidateOnFocus = true,
}: {
  edgeDeviceId: string;
  revalidateOnFocus?: boolean;
}) => {
  const { data, error } = useSwr<AimoParkingEdgeDeviceResponse>(`/edge-device/${edgeDeviceId}`, {
    revalidateOnFocus,
  });

  return {
    data,
    error,
  };
};

export const useUpdateSetting = () => {
  const client = useAimoParkingClient();

  const updateSetting = useCallback(
    async ({
      edgeDeviceId,
      serialNumber,
      setting,
    }: {
      edgeDeviceId: string;
      serialNumber: string;
      setting: object;
    }) => {
      return await client.execute({
        method: "PUT",
        url: `/edge-device/${edgeDeviceId}`,
        data: {
          serialNumber,
          setting,
        },
      });
    },
    [client]
  );

  return { updateSetting };
};

export const useCreateEdgeDevice = () => {
  const client = useAimoParkingClient();

  const create = useCallback(
    async ({ serialNumber, setting, apiKey }: { serialNumber: string; apiKey: string; setting: object }) => {
      return await client.execute({
        method: "POST",
        url: `/edge-device`,
        data: {
          serialNumber,
          setting,
          apiKey,
        },
      });
    },
    [client]
  );

  return { create };
};
