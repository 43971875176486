import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import Breadcrumb from "components/Breadcrumb";
import Button from "components/Button";
import { Board, TitleArea, ContentArea as BaseContextArea, FormRow } from "components/LayoutParts";
import TextButton from "components/TextButton";
import font from "constants/font";
import AimoParkingEdgeDeviceStatusSwitcher from "pages/AimoParking/component/AimoParkingEdgeDeviceStatusSwitcher";
import { PARK_FLOW_URL } from "pages/AimoParking/constants/aimoParkingSiteUrl";
import { useFindEdgeDevice } from "pages/AimoParking/EdgeDevice/api";
import { dateFormat } from "utils/dateTimeUtils";

const Label = styled.div`
  min-width: 160px;
  margin: 0 5px;
`;

const Value = styled.div`
  font-size: ${font.size16};
`;

const FormWrap = styled.div`
  flex: 1;
`;

const ContentArea = styled(BaseContextArea)`
  flex-direction: row;
  margin-bottom: 0;
`;

const ImageWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
`;

const PropertyTitle = styled.div`
  font-size: ${font.size24};
`;

const Margin = styled.div<{ top: number }>`
  margin-top: ${({ top }) => top}px;
`;

const ButtonWrap = styled.div`
  display: flex;
  position: relative;
  margin: 16px 12px 0 -12px;
  right: -32px;
`;

const EdgeDeviceDetail = () => {
  const navigate = useNavigate();
  const { id: edgeDeviceId } = useParams();
  const { data: edgeDevice } = useFindEdgeDevice({ edgeDeviceId: edgeDeviceId as string });

  return (
    <Board>
      <TitleArea>
        <Breadcrumb
          currentPageName={`${edgeDevice?.serialNumber ?? "読み込み中"}`}
          breadcrumbItems={[
            {
              pageName: `エッジデバイス`,
              onClick: () => navigate(`/aimo_parking/edge_device`),
            },
          ]}
        />
      </TitleArea>
      {edgeDevice && (
        <>
          <ContentArea>
            <FormWrap>
              <FormRow>
                <Label>ID</Label>
                <Value>{edgeDevice.id}</Value>
              </FormRow>
              <FormRow>
                <Label>シリアルナンバー</Label>
                <Value>{edgeDevice.serialNumber}</Value>
              </FormRow>
              <FormRow>
                <Label>ステータス</Label>
                <Value>
                  <AimoParkingEdgeDeviceStatusSwitcher status={edgeDevice.status} />
                </Value>
              </FormRow>
              <FormRow>
                <Label>最終接続日時</Label>
                <Value>
                  {edgeDevice.lastConnectionTime ? dateFormat(edgeDevice.lastConnectionTime, "YYYY/MM/DD HH:mm") : ""}
                </Value>
              </FormRow>
              <FormRow>
                <Label>画像</Label>
                <Value>
                  <ImageWrap>
                    {edgeDevice.images &&
                      Object.values(edgeDevice.images).map((image, i) => {
                        return <Image src={image} alt={`device_image_${i}`} key={`image_${i}`} />;
                      })}
                  </ImageWrap>
                </Value>
              </FormRow>
            </FormWrap>
            <ButtonWrap>
              <Button
                label="設定JSONを変更"
                onClick={() => navigate(`/aimo_parking/edge_device/${edgeDevice.id}/edit`)}
                type="primary"
                width="160px"
              />
            </ButtonWrap>
          </ContentArea>
          {edgeDevice.property && (
            <>
              <Margin top={32} />
              <PropertyTitle>駐車場情報</PropertyTitle>
              <Margin top={16} />
              <ContentArea>
                <FormWrap>
                  <FormRow>
                    <Label>ID</Label>
                    <Value>{edgeDevice.property.id}</Value>
                  </FormRow>
                  <FormRow>
                    <Label>駐車場名</Label>
                    <TextButton
                      label={edgeDevice.property.name}
                      onClick={() => {
                        if (edgeDevice?.property == null) return;

                        window.open(
                          `${PARK_FLOW_URL}/search/${edgeDevice.property.id}`,
                          "_blank",
                          "width=800,height=600"
                        );
                      }}
                    />
                  </FormRow>
                </FormWrap>
              </ContentArea>
            </>
          )}
        </>
      )}
    </Board>
  );
};

export default EdgeDeviceDetail;
